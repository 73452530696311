export const appVersion = process.env.REACT_APP_VERSION;
export const nodeEnv = process.env.REACT_APP_ENV;
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
export const serverUrl = process.env.REACT_APP_SERVER_URL;
export const xUserId = process.env.REACT_APP_X_USER_ID;
export const xUsername = process.env.REACT_APP_X_USERNAME;
export const xAccessToken = process.env.REACT_APP_X_ACCESS_TOKEN;
export const xApiKey = process.env.REACT_APP_X_API_KEY;
export const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
export const mapBoxToken = process.env.REACT_APP_MAPBOX_TOKEN;
export const dineInImmediatePayment: boolean =
  process.env.REACT_APP_DINE_IN_IMMEDIATE_PAYMENT?.toLowerCase() === 'true';
export const onlinePaymentOnly: boolean = process.env.REACT_APP_ONLINE_PAYMENT_ONLY?.toLowerCase() === 'true';
