import React from 'react';

const ErrorPage: React.FC = () => {
  return (
    <main className="relative isolate min-h-[100vh]">
      <img src="assets/404.png" alt="" className="absolute inset-0 -z-10 h-full w-full object-cover object-top" />
      <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
        <img className="mx-auto h-10 w-auto sm:h-14" src="/ibp_logo_main.png" alt="logo" />
        <p className="text-lg font-bold leading-8 text-colorPrimary">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-colorPrimary sm:text-5xl">Page not found</h1>
        <p className="mt-4 text-base font-semibold text-colorPrimary/70 sm:mt-6">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <p className="mt-4 text-base font-semibold text-colorPrimary/70 sm:mt-6">
          Please make sure you are using the correct link.
        </p>
      </div>
    </main>
  );
};

export default ErrorPage;
