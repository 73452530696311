/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import { TrashIcon } from '@heroicons/react/24/outline';
import { FC, useRef, useEffect } from 'react';
import { AddTimeSlotModal, AddTimeSlotModalHandle } from './components';

type TResourceTimeSlots = {
  resources: {
    value: number;
    label: string | undefined;
  }[];
  day: string;
  availableDays: string[];
  resourceWithTimeSlots: TResourceTimeSlot[];
  setResourceWithTimeSlots: React.Dispatch<React.SetStateAction<TResourceTimeSlot[]>>;
  showAutoFill: boolean;
};

const getSlotColor = () => {
  switch ('Fixed') {
    case 'Fixed':
      return 'bg-primaryLight';
    default:
      return 'bg-primaryLight';
  }
};

type TResourceTimeSlot = {
  resource: {
    value: number;
    label: string | undefined;
  };
  days: { day: string; timeSlots: { min: number; max: number; color: string }[] }[];
};

const ResourceTimeSlots: FC<TResourceTimeSlots> = ({
  resources,
  availableDays,
  day,
  resourceWithTimeSlots,
  setResourceWithTimeSlots,
  showAutoFill,
}) => {
  const addTimeSlotModalRef = useRef<AddTimeSlotModalHandle>(null);

  const addTimeSlot = (
    selectedResource: {
      resource: {
        value: number;
        label: string | undefined;
      };
      timeSlots: { min: number; max: number; color: string }[];
    },
    timeSlot: { timeRangeStart: number; timeRangeEnd: number },
  ) => {
    setResourceWithTimeSlots((prev) => [
      ...prev.map((prevResource) =>
        prevResource.resource.value === selectedResource?.resource.value
          ? {
              ...prevResource,
              days: prevResource.days.find((prevDay) => prevDay.day === day)
                ? [
                    ...prevResource.days.map((prevDay) =>
                      prevDay.day === day
                        ? {
                            day,
                            timeSlots: [
                              ...prevDay.timeSlots,
                              {
                                min: Number(timeSlot.timeRangeStart),
                                max: Number(timeSlot.timeRangeEnd),
                                color: getSlotColor(),
                              },
                            ],
                          }
                        : prevDay,
                    ),
                  ]
                : [
                    ...prevResource.days,
                    {
                      day,
                      timeSlots: [
                        {
                          min: Number(timeSlot.timeRangeStart),
                          max: Number(timeSlot.timeRangeEnd),
                          color: getSlotColor(),
                        },
                      ],
                    },
                  ],
            }
          : prevResource,
      ),
    ]);
  };

  const removeSingleTimeSlot = (
    resource: {
      value: number;
      label: string | undefined;
    },
    timeSlot: { min: number; max: number },
  ) => {
    setResourceWithTimeSlots((prev) => [
      ...prev.map((prevResource) =>
        prevResource.resource === resource
          ? {
              ...prevResource,
              days: prevResource.days.find((prevDay) => prevDay.day === day)
                ? [
                    ...prevResource.days.map((prevDay) =>
                      prevDay.day === day
                        ? {
                            day,
                            timeSlots: prevDay.timeSlots.filter(
                              (prevTimeSlot) => prevTimeSlot.min !== timeSlot.min && prevTimeSlot.max !== timeSlot.max,
                            ),
                          }
                        : prevDay,
                    ),
                  ]
                : prevResource.days,
            }
          : prevResource,
      ),
    ]);
  };

  const removeAllTimeSlotsForADay = (resource: number) => {
    setResourceWithTimeSlots((prev) => [
      ...prev.map((prevResource) =>
        prevResource.resource.value === resource
          ? {
              ...prevResource,
              days: prevResource.days.filter((prevDay) => prevDay.day !== day),
            }
          : prevResource,
      ),
    ]);
  };

  const autoFillAllDay = () => {
    setResourceWithTimeSlots((prev) =>
      prev.map((prevResource) => {
        const currentDay = prevResource.days.find((prevDay) => prevDay.day === day);
        return {
          ...prevResource,
          days: currentDay
            ? [
                ...availableDays.map((availableDay) => ({
                  day: availableDay,
                  timeSlots: currentDay.timeSlots,
                })),
              ]
            : [],
        };
      }),
    );
  };

  const hourStrings = (hour: number) => {
    if (hour === 0) {
      return '12 AM';
    }
    if (hour < 12) {
      return `${hour} AM`.padStart(5, '0');
    }
    if (hour === 12) {
      return '12 PM';
    }
    return `${hour - 12} PM`.padStart(5, '0');
  };

  useEffect(() => {
    const newResourceWithTimeSlots = resources.map((resource) => {
      const existingResource = resourceWithTimeSlots.find((r) => r.resource.value === resource.value);
      if (existingResource) {
        return existingResource;
      }
      return {
        resource,
        timeSlots: [],
        days: [],
      };
    });
    setResourceWithTimeSlots(newResourceWithTimeSlots);
  }, [resources]);

  return (
    <div>
      {showAutoFill && (
        <div className="w-full flex justify-end">
          <button
            type="button"
            className="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-textLight shadow-sm hover:bg-primaryLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            onClick={autoFillAllDay}
          >
            Auto fill for All Days
          </button>
        </div>
      )}
      <div className="grid grid-cols-12 rounded mt-2 bg-gray-100">
        <AddTimeSlotModal ref={addTimeSlotModalRef} addTimeSlot={addTimeSlot} />
        <div className="col-span-1" />
        <div className="col-span-11 text-center font-bold pt-1">Hours</div>
        <div className="col-span-1" />
        <div className="col-span-11 grid grid-cols-[repeat(24,_1fr)] grid-flow-col py-2">
          {[...Array(24)].map((_, i) => (
            <div key={i} className="flex justify-center mx-[-0.25rem] text-sm">
              {hourStrings(i)}
            </div>
          ))}
        </div>
        {resourceWithTimeSlots.map((resource) => (
          <>
            <div
              className="col-span-1 p-1 border-r font-semibold pl-2 flex justify-center"
              key={resource.resource.value}
            >
              {/* <span>{resource.resource.label}</span> */}
              <div className="flex gap-2">
                <button
                  aria-label="toggle modal"
                  type="button"
                  className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
                  onClick={() =>
                    addTimeSlotModalRef.current?.toggleModal({
                      ...resource,
                      timeSlots: resource.days?.find((rDay) => rDay?.day === day)?.timeSlots || [],
                    })
                  }
                >
                  <svg
                    className="w-4 h-4 text-gray-600"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M12 4v16m8-8H4" />
                  </svg>
                </button>
                <button
                  type="button"
                  className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none"
                  onClick={() => removeAllTimeSlotsForADay(resource.resource.value)}
                >
                  <TrashIcon className="w-4 h-4 text-gray-600" />
                </button>
              </div>
            </div>
            <div className="relative col-span-11">
              <div className="relative z-10 h-8 slider-track grid grid-cols-[repeat(24, minmax(0, 1fr))] gap-px grid-flow-col rounded bg-slate-300">
                {[...Array(24)].map((_, i) => (
                  <div key={i} className="bg-gray-100" />
                ))}
                {resource.days
                  ?.find((rDay) => rDay?.day === day)
                  ?.timeSlots.map((timeslot) => (
                    <div
                      key={`${timeslot.min}-${resource.resource}`}
                      className={`absolute z-20 top-0 h-8 bottom-0 rounded-md ${timeslot.color} flex justify-center items-center`}
                      style={{
                        left: `${(timeslot.min / 24) * 100}%`,
                        right: `${100 - (timeslot.max / 24) * 100}%`,
                      }}
                      onClick={() => removeSingleTimeSlot(resource.resource, timeslot)}
                    />
                  ))}
              </div>
              {/* <TimeSlotSlider color={colors[index]} /> */}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default ResourceTimeSlots;
