/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import './header.css';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Badge, Button, Modal } from 'antd';
import { InformationCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Bounce, toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import SideMenu, { DrawerHandle } from '../sidemenu/sidemenu.component';
import { SearchPalette, SearchPaletteHandle } from './components';
import api from '../../config/api/api.config';
import { xAccessToken, xApiKey, xUserId } from '../../config/variables/system.variable';
import headerApi from './header.api';
import { DataLoadingOverlay } from '../common/components';
import { ThemeContext } from '../../contexts/theme.context';
import useOrderCreationStore from '../hooks/useOrderCreation.hook';
import { TBrandDetails, TDiningArea, TDiningTable, TMerchantBranch, TMerchantDetails } from '../common/types';
import useCurrentUser from '../hooks/useCurrentUser.hook';
import { cn, hexToRgba } from '../common/services/utils.service';
import sharedApi, { TManualPushRecommendation } from '../common/shared.api';
import { useRecommendationModal } from '../common/hooks';

const Header: React.FC = () => {
  const { setRecommendationsStore } = useRecommendationModal();
  const { setCurrentUser } = useCurrentUser();
  const { orderCreationStore, setOrderCreationStore, reset } = useOrderCreationStore();
  const { orderTicket, menu } = orderCreationStore;
  const [sParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const sideMenuRef = useRef<DrawerHandle>(null);
  const searchPaletteRef = useRef<SearchPaletteHandle>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [basketItems, setBasketItems] = useState(0);
  const brandDetails: TBrandDetails | null = JSON.parse(localStorage.getItem('brandDetails') || 'null');
  const merchantDetails: TMerchantDetails | null = JSON.parse(localStorage.getItem('merchantDetails') || 'null');
  const customerOrder = localStorage.getItem('customerOrderId');
  const searchParams = new URLSearchParams(window.location.search);
  const externalOrderReferenceNumber = searchParams.get('external_order_reference_nr');
  const brandCode = searchParams.get('b');
  const tableId = searchParams.get('t');
  const branchId = searchParams.get('br');
  const merchantCode = searchParams.get('m');
  const paymentPointUrl = searchParams.get('ppu');
  const paymentPointId = searchParams.get('ppi');
  const selectedLocation = localStorage.getItem('selectedLocation');
  const [manualPushRecommendations, setManualPushRecommendations] = useState<TManualPushRecommendation[]>([]);
  const [consumedRecommendations, setConsumedRecommendations] = useState<number[]>([]);
  const orderType = localStorage.getItem('orderType');

  const { theme, setThemeObject } = useContext(ThemeContext);

  const orderTicketItems = orderTicket.item_list.map((item) => item.item_id);

  const fulfilmentTypeMenu = menu.map((category) => ({
    ...category,
    menu_items: category.menu_items
      .filter(
        (item) =>
          item.item_price_list.length === 0 ||
          item.item_price_list.find(
            (price) =>
              price.fulfillment_type_id === orderCreationStore.orderTicket.integration_details.fulfillment_type_id,
          ),
      )
      .map((item) => ({ ...item, item_category_id: category.item_category_id })),
  }));

  const selectedMenuCategory = fulfilmentTypeMenu
    .flatMap((category) => category.menu_items)
    .sort((a, b) => a.menu_item_name_en.localeCompare(b.menu_item_name_en))
    .sort((a, b) => Number(a.excluded_p) - Number(b.excluded_p));

  const nonConsumedRecommendations = manualPushRecommendations.filter(
    (r) =>
      !consumedRecommendations.includes(r.menu_item_recommendation_id) &&
      !orderTicketItems.includes(r.item_id) &&
      selectedMenuCategory.find((item) => item.item_id === r.item_id),
  );

  const checkMenuItemRecommendation = async () => {
    const { data, status } = await sharedApi.getMenuItemRecommendations(orderTicket.integration_details.brand_id || 0);

    if (status === 200 && data.status === 'successful' && data.data) {
      setManualPushRecommendations(data.data.manual_push.filter((r) => r.customer_recommendation_p));
      const recommendedItem = selectedMenuCategory.find(
        (item) =>
          data.data.manual_push?.[0]?.customer_recommendation_p &&
          item.item_id === data.data.manual_push[0].item_id &&
          !orderTicketItems.includes(item.item_id),
      );

      if (recommendedItem)
        toast.info('Live Chef Recommendation!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          style: {
            borderRadius: '15px',
            top: isMobile ? '0px' : '80px',
            marginTop: isMobile ? '50px' : '0px',
            marginLeft: isMobile ? '10px' : 'auto',
            marginRight: isMobile ? '10px' : 'auto',
            backgroundColor: theme?.general?.buttonColor ? hexToRgba(theme?.general?.buttonColor, 1) : '',
            width: isMobile ? '95%' : '100%',
            color: 'white',
          },
          progressStyle: {
            height: '10px',
            backgroundColor: theme?.general?.buttonColor || 'black',
            filter: 'invert(0.4)',
          },
          icon: <InformationCircleIcon className="h-8 w-8 text-white" style={{ color: 'white' }} />,
          transition: Bounce,
          onClick: () => {
            setRecommendationsStore({
              open: true,
              recommendations: data.data.manual_push[0] ? [data.data.manual_push[0]] : [],
              recommendedItemIds: recommendedItem?.item_id ? [recommendedItem?.item_id] : [],
            });
          },
        });
    }
  };

  const fetchMenu = async (branch: TMerchantBranch) => {
    setIsLoading(true);
    const { data, status } = await headerApi.getMenu(branch.branch_id);
    if (status === 200) {
      sParams.delete('t');
      sParams.delete('ppu');
      sParams.delete('ppi');
      if (paymentPointUrl) localStorage.setItem('paymentPointUrl', paymentPointUrl);
      localStorage.removeItem('iBlinkMarketplaceUser');
      localStorage.removeItem('customerDetails');
      localStorage.removeItem('phoneNumber');
      localStorage.removeItem('brandId');
      localStorage.removeItem('brandCode');
      localStorage.removeItem('savedLocations');
      localStorage.removeItem('selectedPickupLocation');
      localStorage.removeItem('selectedDeliveryLocation');
      localStorage.removeItem('orderType'); // ? remove

      const updatedMenu = data.data?.map((category) => ({
        ...category,
        menu_items: category.menu_items?.map((item) => ({
          ...item,
          item_category_id: category.item_category_id,
        })),
      }));
      const updatedStore: any = reset();
      let diningArea: TDiningArea | null = null;
      setTimeout(async () => {
        if (tableId) {
          const { data: dinningAreasData, status: dinningAreasStatus } = await sharedApi.getFacilityDiningAreas(
            branch.facility.facility_id,
          );
          if (dinningAreasStatus === 200) {
            diningArea =
              dinningAreasData.data.find((area: TDiningArea) =>
                area.dining_table_list.find((table: TDiningTable) => table.dining_table_id === Number(tableId)),
              ) || null;
          }
        }
        localStorage.setItem('orderType', diningArea ? 'dine-in' : 'restaurant-delivery');
        setOrderCreationStore({
          ...updatedStore,
          orderTicket: {
            ...updatedStore.orderTicket,
            ...(diningArea
              ? {
                  dining_table_id: tableId,
                  dining_table_name: diningArea?.dining_table_list.find(
                    (table) => table.dining_table_id === Number(tableId),
                  )?.dining_table_name,
                  dining_area_id: diningArea?.dining_area_id,
                  dining_area_name: diningArea?.dining_area_name,
                }
              : {}),
            integration_details: {
              ...updatedStore.orderTicket.integration_details,
              facility_id: branch.facility.facility_id,
              branch_id: branch.branch_id,
              brand_id: branch.brand.brand_id,
              marketplace_id: branch.marketplace.marketplace_id,
              fulfillment_type: diningArea ? 'dine-in' : 'restaurant-delivery',
              fulfillment_type_id: diningArea ? 1 : 0,
            },
            payment_point_id: paymentPointId,
          },
          menu: updatedMenu,
          userConfig: undefined,
          availableFulfillmentTypes: branch.facility.fulfillment_types,
        });
        setSearchParams(sParams);
        window.location.href = '/menu';
      }, 100);
    } else {
      Modal.info({
        title: 'Location not supported',
        content: (
          <div>
            <p>We are not available on the selected location.</p>
          </div>
        ),
        className: 'rounded-md',
      });
      setIsLoading(false);
    }
  };

  const fetchMerchantDetails = async () => {
    if (!merchantCode) return;
    const { data, status } = await headerApi.getMerchantDetails(merchantCode);
    if (status === 200) {
      setCurrentUser(undefined);
      const logoImage = data.data.merchant_image_list.find((image) => image.image_type === 'logo')?.image_url || null;
      const coverImage = data.data.merchant_image_list.find((image) => image.image_type === 'cover')?.image_url || null;
      const bannerImage =
        data.data.merchant_image_list.find((image) => image.image_type === 'banner')?.image_url || null;

      localStorage.setItem(
        'merchantDetails',
        JSON.stringify({
          ...data.data,
          logo_image: logoImage,
          cover_image: coverImage,
          banner_image: bannerImage,
        }),
      );
      sParams.delete('b');
      sParams.delete('m');
      localStorage.removeItem('iBlinkMarketplaceUser');
      localStorage.removeItem('diningAreas');
      localStorage.removeItem('customerDetails');
      localStorage.removeItem('phoneNumber');
      localStorage.removeItem('brandCode');
      localStorage.removeItem('brandCode');
      localStorage.removeItem('savedLocations');
      localStorage.removeItem('selectedPickupLocation');
      localStorage.removeItem('selectedDeliveryLocation'); // ? remove
      localStorage.removeItem('orderType'); // ? remove
      reset();

      if (data.data.merchant_web_element_list) {
        const logoElement = data.data.merchant_web_element_list.header?.find(
          (element: { web_element_name: string }) => element.web_element_name === 'brand_logo',
        )?.merchant_facility_web_element_image_map_list;
        const headerElements = {
          backgroundColor: data.data.merchant_web_element_list.header?.find(
            (element: { web_element_name: string }) => element.web_element_name === 'background_color',
          )?.web_element_value,
          textColor: data.data.merchant_web_element_list.header?.find(
            (element: { web_element_name: string }) => element.web_element_name === 'text_color',
          )?.web_element_value,
          textColorSecondary: data.data.merchant_web_element_list.header?.find(
            (element: { web_element_name: string }) => element.web_element_name === 'text_color_secondary',
          )?.web_element_value,
          logoUrl: logoElement ? logoElement[0]?.merchant_facility_web_element_image_map_url : undefined,
        };
        const generalElements = {
          backgroundColor: data.data.merchant_web_element_list.general?.find(
            (element: { web_element_name: string }) => element.web_element_name === 'background_color',
          )?.web_element_value,
          buttonColor: data.data.merchant_web_element_list.general?.find(
            (element: { web_element_name: string }) => element.web_element_name === 'button_color',
          )?.web_element_value,
        };
        setThemeObject({ ...theme, merchant: { header: headerElements, general: generalElements } });
      }

      setSearchParams(sParams);
    }
    setIsLoading(false);
  };

  const fetchBrandDetails = async () => {
    if (!brandCode) return;
    const { data, status } = await headerApi.getBrandDetails(brandCode);
    if (status === 200) {
      setCurrentUser(undefined);
      const logoImage = data.data.brand_image_list.find((image) => image.image_type === 'logo')?.image_url || null;
      const coverImage = data.data.brand_image_list.find((image) => image.image_type === 'cover')?.image_url || null;
      const bannerImage = data.data.brand_image_list.find((image) => image.image_type === 'banner')?.image_url || null;

      localStorage.setItem(
        'brandDetails',
        JSON.stringify({
          ...data.data,
          logo_image: logoImage,
          cover_image: coverImage,
          banner_image: bannerImage,
        }),
      );

      sParams.delete('b');
      sParams.delete('br');
      sParams.delete('m');
      localStorage.removeItem('iBlinkMarketplaceUser');
      localStorage.removeItem('diningAreas');
      localStorage.removeItem('customerDetails');
      localStorage.removeItem('phoneNumber');
      localStorage.removeItem('brandCode');
      localStorage.removeItem('brandCode');
      localStorage.removeItem('savedLocations');
      localStorage.removeItem('selectedPickupLocation');
      localStorage.removeItem('selectedDeliveryLocation'); // ? remove
      localStorage.removeItem('orderType'); // ? remove
      const updatedStore: any = reset();
      setTimeout(async () => {
        setOrderCreationStore({
          ...updatedStore,
          orderTicket: {
            ...updatedStore.orderTicket,
            customer_address_details: undefined,

            integration_details: {
              brand_id: data.data.brand_id,
              fulfillment_type: 'restaurant-delivery',
              fulfillment_type_id: 0,
            },
          },
          userConfig: undefined,
          address: undefined,
        });
        if (data.data.brand_facility_web_element_list) {
          const logoElement = data.data.brand_facility_web_element_list.header?.find(
            (element: { web_element_name: string }) => element.web_element_name === 'brand_logo',
          )?.brand_facility_web_element_image_map_list;
          const headerElements = {
            backgroundColor: data.data.brand_facility_web_element_list.header?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'background_color',
            )?.web_element_value,
            textColor: data.data.brand_facility_web_element_list.header?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'text_color',
            )?.web_element_value,
            textColorSecondary: data.data.brand_facility_web_element_list.header?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'text_color_secondary',
            )?.web_element_value,
            logoUrl: logoElement ? logoElement[0]?.brand_facility_web_element_image_map_url : undefined,
          };
          const generalElements = {
            backgroundColor: data.data.brand_facility_web_element_list.general?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'background_color',
            )?.web_element_value,
            buttonColor: data.data.brand_facility_web_element_list.general?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'button_color',
            )?.web_element_value,
            cardBackgroundColor: data.data.brand_facility_web_element_list.general?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'card_background_color',
            )?.web_element_value,
            textColor: data.data.brand_facility_web_element_list.general?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'text_color',
            )?.web_element_value,
            descriptionTextColor: data.data.brand_facility_web_element_list.general?.find(
              (element: { web_element_name: string }) => element.web_element_name === 'description_text_color',
            )?.web_element_value,
          };
          localStorage.setItem('theme elements', JSON.stringify(data.data.brand_facility_web_element_list));
          setThemeObject({ header: headerElements, general: generalElements });
        }
        setSearchParams(sParams);

        if (branchId) {
          setIsLoading(true);
          const { data: branchData } = await sharedApi.getMerchantBranches(
            {
              branch_id: Number(branchId),
            },
            data?.data.merchant.merchant_id || 0,
          );
          if (branchData.data.length > 0) fetchMenu(branchData.data[0]);
          else setIsLoading(false);
        }
        // window.location.reload();
      }, 100);
      setIsLoading(false);
    } else navigate('/404', { state: { message: 'Please scan a valid QR code to continue' } });
  };

  const setAuthentication = () => {
    const iBlinkMarketplaceUser = localStorage.getItem('iBlinkMarketplaceUser');

    if (iBlinkMarketplaceUser) {
      // if (JSON.parse(iBlinkMarketplaceUser)?.role === 'merchant') navigate('/merchant/bookings');
      api.setAuthenticationHeader({
        token: JSON.parse(iBlinkMarketplaceUser).token,
        id: JSON.parse(iBlinkMarketplaceUser).id,
        apiKey: JSON.parse(iBlinkMarketplaceUser).apiKey,
      });
    } else {
      api.setAuthenticationHeader({ token: xAccessToken || '', id: xUserId || '', apiKey: xApiKey || '' });
    }
  };

  const getLogoImage = () => {
    if (merchantDetails && ['/', '/brands'].includes(pathname)) {
      return merchantDetails.logo_image || '/ibp_logo_main.png';
    }
    if (brandDetails) {
      return brandDetails.logo_image || '/ibp_logo_main.png';
    }
    return '/ibp_logo_main.png';
  };

  const getTheme = () => {
    if (merchantDetails && ['/', '/brands'].includes(pathname)) {
      return theme.merchant;
    }
    if (brandDetails) {
      return theme;
    }
    return theme;
  };

  const themeObj = getTheme();

  useEffect(() => {
    setBasketItems(orderCreationStore.orderTicket.item_list.map((item) => item.quantity).reduce((a, b) => a + b, 0));
  }, [orderCreationStore.orderTicket.item_list]);

  useEffect(() => {
    if (orderCreationStore.menu.length === 0)
      navigate(merchantDetails && localStorage.getItem('merchantBranches') ? '/brands' : '/');
  }, [orderCreationStore.menu, merchantDetails]);

  useEffect(() => {
    if (pathname === '/brands' && !localStorage.getItem('merchantBranches')) navigate('/');
  }, [pathname]);

  useEffect(() => {
    if (orderTicket.integration_details.brand_id && theme.general && window.location.pathname === '/menu') {
      setTimeout(() => {
        checkMenuItemRecommendation();
      }, 2000);
    }
  }, [orderTicket.integration_details.brand_id, theme]);

  useEffect(() => {
    setAuthentication();

    if (externalOrderReferenceNumber) {
      localStorage.setItem('customerOrderId', externalOrderReferenceNumber);
      navigate(`/order-summary?orderId=${externalOrderReferenceNumber}`);
    } else if (brandCode || merchantCode) {
      // removed transactionPointId
      localStorage.removeItem('iBlinkMarketplaceUser');
      localStorage.removeItem('phoneNumber');
      localStorage.removeItem('brandCode');
      localStorage.removeItem('merchantCode');
      localStorage.removeItem('customerOrderId');
      localStorage.removeItem('orderCreationStore');
      localStorage.removeItem('merchantDetails');
      localStorage.removeItem('brandDetails');
      localStorage.removeItem('merchantBranches');
      localStorage.removeItem('theme');
      setIsLoading(true);
      if (merchantCode) fetchMerchantDetails();
      if (brandCode) fetchBrandDetails();
    } else if (
      !(localStorage.getItem('brandDetails') || localStorage.getItem('merchantDetails')) &&
      !localStorage.getItem('iBlinkMarketplaceUser')
    )
      navigate('/404', { state: { message: 'Please scan a valid QR code to continue' } });
    else if (customerOrder && pathname !== '/order-summary') {
      console.log('Navigating to order summary');
      navigate(`/order-summary?orderId=${customerOrder}`);
    }
  }, []);

  useEffect(() => {
    if (merchantDetails && ['/', '/brands'].includes(pathname)) {
      document.title = merchantDetails.merchant_name
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = merchantDetails.logo_image ? merchantDetails.logo_image : 'logo.png';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    if (brandDetails && !['/', '/brands'].includes(pathname)) {
      document.title = brandDetails.brand_name
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = brandDetails.logo_image ? brandDetails.logo_image : 'logo.png';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }, [brandDetails, merchantDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // useEffect(() => {
  //   if (orderCreationStore) {
  //     console.log('orderCreationStore', orderCreationStore);
  //     localStorage.setItem('orderCreationStore', JSON.stringify(orderCreationStore));
  //   }
  // }, [orderCreationStore]);

  return (
    <>
      <DataLoadingOverlay isLoading={isLoading} />
      <nav
        className={cn(`h-[11vh] sm:h-[7vh] flex items-center justify-between flex-wrap p-4 top-0 right-0 left-0 z-50`, {
          'bg-colorPrimary': !themeObj?.header?.backgroundColor,
        })}
        style={{
          backgroundColor: themeObj?.header?.backgroundColor || '',
          color: themeObj?.header?.textColor || '',
        }}
      >
        <div className="text-textPrimary mr-6" style={{ color: themeObj?.header?.textColor || '' }}>
          <button
            type="button"
            className="text-textPrimary hover:text-textPrimary"
            style={{ color: themeObj?.header?.textColor || '' }}
            onClick={() => {
              if (localStorage.getItem('merchantDetails')) navigate('/brands');
              else navigate(orderCreationStore.menu.length > 0 ? '/menu' : '/');
            }}
          >
            {/* themeObj?.header?.logoUrl ||  */}
            <img
              src={getLogoImage()}
              alt="brand-logo"
              className="h-[5vh] sm:h-[4vh]"
              onError={(e) => {
                e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/brand.png`;
              }}
            />
          </button>
        </div>
        <div className="flex justify-end w-full md:w-auto flex-1 mt-2 md:mt-0">
          <div className="w-max flex justify-end md:justify-start">
            {!['/checkout', '/order-summary'].includes(pathname) && pathname !== '/' && (
              <div className="flex bottom-0 right-0 items-center rounded-full">
                {pathname !== '/' && selectedLocation ? (
                  <button
                    type="button"
                    className="mr-2 disabled:opacity-100 capitalize"
                    disabled={orderType === 'dine-in'}
                    onClick={() =>
                      Modal.confirm({
                        title: 'Change Location',
                        content: (
                          <div>
                            <p>Are you sure you want to change the location?</p>
                          </div>
                        ),
                        onOk: () => {
                          localStorage.removeItem('selectedLocation');
                          localStorage.removeItem('selectedPickupLocation');
                          localStorage.removeItem('selectedDeliveryLocation');
                          localStorage.removeItem('orderType');
                          localStorage.removeItem('orderCreationStore');
                          localStorage.removeItem('customerOrderId');
                          localStorage.removeItem('merchantBranches');
                          reset();
                          navigate('/');
                        },
                        okText: 'Yes',
                        className: 'rounded-md',
                      })
                    }
                  >
                    {' '}
                    {[orderCreationStore.orderTicket.dining_table_name, orderType !== 'dine-in' ? selectedLocation : '']
                      .filter((loc) => loc)
                      .join(', ')}
                  </button>
                ) : null}
                {pathname !== '/brands' ? (
                  <Button
                    className="!rounded-full p-1"
                    onClick={() => {
                      searchPaletteRef.current?.toggleSearch();
                    }}
                    icon={
                      <MagnifyingGlassIcon
                        className="h-5 w-5 text-gray-400 hover:invert"
                        aria-hidden="true"
                        style={{ color: themeObj?.header?.cardBackgroundColor || '' }}
                      />
                    }
                  />
                ) : null}
                <div className="w-4" />
                <button
                  type="button"
                  aria-label="use ai"
                  className="h-10 w-10"
                  disabled={nonConsumedRecommendations.length === 0}
                  onClick={() => {
                    const nRecommendation = nonConsumedRecommendations[0];
                    const rItem = selectedMenuCategory.find((item) => item.item_id === nRecommendation?.item_id);
                    if (rItem) {
                      setRecommendationsStore({
                        open: true,
                        recommendations: [nRecommendation],
                        recommendedItemIds: [nRecommendation.item_id],
                      });
                      setConsumedRecommendations([
                        ...consumedRecommendations,
                        nRecommendation.menu_item_recommendation_id,
                      ]);
                    }
                  }}
                >
                  <Badge
                    count={nonConsumedRecommendations.length}
                    offset={[-5, 5]}
                    style={{
                      backgroundColor: themeObj?.general?.buttonColor || '',
                      borderColor: themeObj?.general?.buttonColor || '',
                    }}
                  >
                    <img src={`${process.env.PUBLIC_URL}/iblinkx-ai.gif`} alt="" className="object-cover" />
                  </Badge>
                </button>
              </div>
            )}
            {/* <input
              type="search"
              id="default-search"
              className="block p-2 pl-10 w-full md:w-72 text-sm rounded-full bg-white placeholder-gray-400 text-black focus:ring-gray-50 focus:border-gray-50"
              placeholder="Dishes, restaurants or cuisines"
              required
            /> */}
            <SearchPalette ref={searchPaletteRef} />
          </div>
          {/* <div className="md:w-10 block flex-grow lg:flex lg:items-center lg:w-auto col-span-1">
            <div className="w-full">
              {!['/checkout', '/order-summary', '/brands'].includes(pathname) && pathname !== '/' && (
                <button
                  type="button"
                  onClick={basketPressed}
                  className="inline-block text-sm sm:px-4 md:px-0 sm:py-2 md:py-0 leading-none lg:mt-0 float-right w-10"
                >
                  <Badge count={basketItems}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/shopping_basket.png`}
                      alt="basket"
                      className="w-6 text-white"
                    />
                  </Badge>
                </button>
              )}
            </div>
          </div> */}
        </div>
      </nav>
      <div>
        <SideMenu ref={sideMenuRef} />
      </div>
    </>
  );
};

export default Header;
