/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { notification } from 'antd';
import { isMobile } from 'react-device-detect';
import { Bounce, toast } from 'react-toastify';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ThemeContext } from '../../../../contexts/theme.context';
import utils, { hexToRgba } from '../../../common/services/utils.service';
import useOrderCreationStore from '../../../hooks/useOrderCreation.hook';
import { TMenuItem, TOrderLineItem } from '../../../common/types';
import { useIsLoading, useRecommendationModal } from '../../../common/hooks';
import 'react-toastify/dist/ReactToastify.css';
import sharedApi from '../../../common/shared.api';
import MenuItem from '../menu-item.component';

type Props = {
  gridColumns?: string;
  imageSize?: string;
};

const ProductsGrid = ({ gridColumns, imageSize }: Props) => {
  const { setIsLoading } = useIsLoading();
  const { orderCreationStore, addLineItemToTicket } = useOrderCreationStore();
  const { setRecommendationsStore } = useRecommendationModal();
  const { orderTicket, menu } = orderCreationStore;
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const checkMenuItemRecommendation = async (menuItem: TMenuItem) => {
    const { data, status } = await sharedApi.getMenuItemRecommendationByItemIds([menuItem.item_id]);

    if (status === 200 && data.status === 'successful' && data.data.length > 0) {
      const fulfilmentTypeMenu = menu.map((category) => ({
        ...category,
        menu_items: category.menu_items
          .filter(
            (item) =>
              item.item_price_list.length === 0 ||
              item.item_price_list.find(
                (price) =>
                  price.fulfillment_type_id === orderCreationStore.orderTicket.integration_details.fulfillment_type_id,
              ),
          )
          .map((item) => ({ ...item, item_category_id: category.item_category_id })),
      }));

      const selectedMenuCategory = fulfilmentTypeMenu
        .flatMap((category) => category.menu_items)
        .sort((a, b) => a.menu_item_name_en.localeCompare(b.menu_item_name_en))
        .sort((a, b) => Number(a.excluded_p) - Number(b.excluded_p));

      const orderTicketItems = orderTicket.item_list.map((item) => item.item_id);

      const recommendedItem = selectedMenuCategory.find(
        (item) =>
          data.data[0].customer_recommendation_p &&
          item.item_id === data.data[0].recommended_item_id &&
          !orderTicketItems.includes(item.item_id),
      );
      if (recommendedItem)
        toast.info('Live Chef Recommendation!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          style: {
            borderRadius: '15px',
            marginTop: isMobile ? '100px' : '80px',
            marginLeft: isMobile ? '10px' : 'auto',
            marginRight: isMobile ? '10px' : 'auto',
            backgroundColor: theme?.general?.buttonColor ? hexToRgba(theme?.general?.buttonColor, 1) : '',
            color: 'white',
          },
          progressStyle: {
            height: '10px',
            backgroundColor: theme?.general?.buttonColor || 'black',
            filter: 'invert(0.4)',
          },
          icon: <InformationCircleIcon className="h-8 w-8 text-white" style={{ color: 'white' }} />,
          transition: Bounce,
          onClick: () => {
            setRecommendationsStore({
              open: true,
              recommendations: data.data[0] ? [data.data[0]] : [],
              recommendedItemIds: recommendedItem?.item_id ? [recommendedItem?.item_id] : [],
            });
          },
        });
    }
  };

  const showNotification = (menuItem: TMenuItem) => {
    toast.info(
      <div>
        <img
          src={
            menuItem?.menu_item_images && menuItem?.menu_item_images.length > 0
              ? menuItem?.menu_item_images[0]?.menu_item_image_url
              : `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`
          }
          alt="product"
          className="h-16 w-16 object-cover float-left rounded-lg"
          onError={(e) => {
            e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`;
          }}
        />
        <div className="ml-20 capitalize">
          <b>{menuItem?.menu_item_name_en}</b>
        </div>
        <div className="ml-20">(X1) Added to basket</div>
      </div>,
    );
  };

  const onAddMenuItem = async (item: TMenuItem) => {
    setIsLoading(true);
    const orderLineItem: TOrderLineItem = {
      id: uuidv4(),
      external_item_id: item.menu_item_external_id,
      item_id: item.item_id,
      item_category_id: item.item_category_id || 0,
      display_name_en: item.menu_item_name_en,
      display_name_ar: item.menu_item_name_ar,
      menu_item_image: item.menu_item_images.length > 0 ? item.menu_item_images[0].menu_item_image_url : '',
      item_price: item.menu_item_price,
      price: item.menu_item_price,
      item_price_list: item.item_price_list || [],
      quantity: 1,
      modifier_section_list: [],
      modifierSubtotal: 0,
      currency_alpha_3: item.currency_alpha_3,
    };
    if (item.modifier_sections.length === 0) {
      const itemAdded = await addLineItemToTicket(orderLineItem);
      if (itemAdded) {
        showNotification(item);

        // checkMenuItemRecommendation(item); // ! TODO: check if this is needed
      } else
        notification.error({
          message: 'Something went wrong',
          description: `Unable to add ${item.menu_item_name_en} to the basket, please try again later`,
          placement: isMobile ? 'bottom' : 'topRight',
          duration: 1.5,
        });
    } else navigate(`/product/${item.item_id}`);
    setIsLoading(false);
  };

  return (
    <div>
      {menu?.map((category) => (
        <div key={category.item_category_external_id}>
          <div className="p-4 font-bold text-lg capitalize">{category.menu_category_name_en}</div>
          <div className={`grid ${gridColumns} gap-4`}>
            {category?.menu_items?.map((menuItem) => (
              <MenuItem
                key={menuItem.menu_item_external_id}
                menuItem={menuItem}
                imageSize={imageSize}
                theme={theme}
                onAddMenuItem={onAddMenuItem}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

ProductsGrid.defaultProps = {
  gridColumns: 'grid-cols-1 lg:grid-cols-3',
  imageSize: 'h-28 w-28 md:h-28 md:w-28 ',
};

export default ProductsGrid;
